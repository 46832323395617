@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

/* #root{
  overflow-y: auto !important;
} */
.card-shadow{
  box-shadow: rgba(99, 99, 99, 0.103) 0px 2px 8px 0px;
}

/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}

@-webkit-keyframes bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling {
  0% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling {
  0% { background-position: 50px 50px; }
}

.ReactVirtualized__Grid{
  margin: auto !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}


/* Main styles */
.section {
  /* color: #999;
  font: 400 16px/1.5  "Source Serif 4", Georgia, serif;
  text-align: center; */
  /* img size is 50x50 */
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse .92s infinite; /* Safari 4+ */
  -moz-animation:    bg-scrolling-reverse .92s infinite; /* Fx 5+ */
  -o-animation:      bg-scrolling-reverse .92s infinite; /* Opera 12+ */
  animation:         bg-scrolling-reverse .92s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function:    linear;
  -o-animation-timing-function:      linear;
  animation-timing-function:         linear;

}


.active {
  background-color: #603710;
  color: #F0E6D2;
}

.active>li {
  background-color: #603710 !important;
}

#navlink {
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

#navlink:hover.active {
  color: #F0E6D2 !important;
  background-color: #603710 !important;
}

#navlink:hover {
  color: #603710;
  background: #F0E6D2;
}

.animate-bottom {
  animation-name: animate;
  animation-duration: 5s;
  animation-timing-function: ease;
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.animate-width {
  transition: all 0.5s;
  transition-timing-function: ease-in-out;
  animation-name: changewidth;
  animation-duration: 0.5s;
}

@keyframes changewidth {
  0% {
    width: 0px;
  }
  100% {
    width: 40px;
  }

}

@keyframes animate {
  0% {
    opacity: 0;
    display: none;
  }

  50% {
    display: grid;
  }

  100% {
    opacity: 1;
  }

}


.swiper1 {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper2 {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  height: auto;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
